import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css'
import Background from './Background'
import Foreground from './Foreground';
import { useTitle } from 'hooks/useTitle';

const Home = () => {
    useTitle("Hatzolah of Linden | Home");

    return (
       <Background> 
        <Foreground />
       </Background>
    )};

export default Home;