
import React, {useEffect, useState} from 'react';
import { Button, Modal } from 'react-bootstrap';
import styles from './Confirm.module.css'

const Confirm = (props) => {
    const [show, setShow] = useState(props.show);

    useEffect(()=>{
      setShow(props.show)
    },[props.show]);

    const runFn = (fn) => {
        if(fn){
            fn();
        }
        setShow(false);
    };

    return (
        <Modal className={`${styles.confirm}`} show={show} onHide={()=>runFn(props.noFn)}>
          <Modal.Header className={`${styles.modalHeader}`} closeButton>
            <Modal.Title>{props.caption}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{props.body}</Modal.Body>
          <Modal.Footer>
          <Button variant="primary" className={`${styles.yesBtn}`} onClick={()=>runFn(props.yesFn)}>
              Yes
            </Button>
            <Button variant="secondary" onClick={()=>runFn(props.noFn)}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
    );
};

export default Confirm;

