import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styles from './ContactInfo.module.css'

const ContactInfo = () => {
    return (
        <Container className={styles.contactInfo}>
            <Row className={styles.row}>
                <Col className={styles.left} md='3'>
                    <span className={styles.caption}><b>CONTACT</b></span>
                </Col>
                <Col className={styles.left} md='9'>
                    <span ><b></b></span>
                </Col>
            </Row>
            <Row style={{height:'15px'}}>
            </Row>
            <Row  className={styles.row}>
                <Col className={styles.left} md='12'>
                    <span className={styles.text}><b>Office:</b>&nbsp;&nbsp; 25 Commerce Drive Suite #130</span>
                </Col>
            </Row>
            <Row  className={styles.row}>
                <Col className={styles.left} sm='3'>
                    
                </Col>
                <Col className={styles.left} sm='9'>
                    <span className={styles.text}>Cranford, NJ 07016</span>
                </Col>
            </Row>
            <Row  className={styles.row}>
                <Col className={styles.left} md='12'>
                    <span className={styles.text}><b>Mailing:</b>&nbsp;&nbsp; P.O.B. 1974 Linden, NJ 07036</span>
                </Col>
            </Row>
            <Row  className={styles.row}>
                <Col className={styles.left} md='12'>
                    <span className={styles.text}><b>Office Phone:</b>&nbsp;&nbsp;908.336.5939</span>
                </Col>
            </Row>
            <Row  className={styles.row}>
                <Col className={styles.left} md='12'>
                    <span className={styles.text}><b>Email:</b>&nbsp;&nbsp;office@lindenhatzolah.com</span>
                </Col>
            </Row>
        </Container>
    );
}

export default ContactInfo;
