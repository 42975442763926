import React from 'react'
import './Footer.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {Image} from 'react-bootstrap';
import ContactInfo from 'footer/ContactInfo';
import BoardOfDirectors from './BoardOfDirectors';
import Coordinators from './Coordinators';
import Rabbis from './Rabbis';

const Footer = () => {
    return (
            <footer className='footer'>
                <div className='outer'>
                    {/* <Row> */}
                        <div className='tbl'> 
                            <BoardOfDirectors/>   
                        </div>
                        <div className='tbl'>
                            <Coordinators/>  
                        </div>
                        <div className='img-cell'>
                            <div className='img'><Image className='logo' alt="LindenHatzolahLogo" src="linden-hatzolah-logo.png" rounded /></div>
                        </div>
                        <div className='tbl'>
                            <ContactInfo />
                        </div>
                        <div className='tbl'>
                            <Rabbis/> 
                        </div> 
                    {/* </Row> */}
                </div>
            <div className='ad'><span>Powered by <b>Precision Developers</b> 347.675.3740</span></div>
        </footer>
    )
}

export default Footer;