import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import {NavLink} from 'react-router-dom';
import './TopNav.css'

const TopNav = () => {
    return (
        <nav className="flex-row navbar navbar-expand-sm menu navbar-light bg-light">
            <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse flex-right mr15" id="navbarSupportedContent">
                <div className="g20 navbar-nav mr-auto">
                    <NavLink to="/" activeClassName="active" exact={true}>Home</NavLink>
                    <NavLink to="about" activeClassName="active">About Us</NavLink>
                    <NavLink to="contact" activeClassName="active">Contact</NavLink>    
                </div>
                <NavLink to="donate"  activeClassName="active" exact={true}><button className='donate-menu-btn'>DONATE</button></NavLink>
            </div>
        </nav>
    );
}

export default TopNav;