import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './ContactInfo.module.css'

const Rabbis = () => {
    return (
    <Container className={styles.contactInfo}>
    <Row className={styles.row}>
        <Col className={styles.left} md='12'>
            <span className={styles.caption}><b>ועד הרבנים</b></span>
        </Col>
    </Row>
    <Row style={{height:'15px'}}>
    </Row>
    <Row  className={styles.row}>
        <Col className={styles.left} md='12'>
            <span className={styles.text}>כ"ק אדמו"ר מקאסאן שליט"א</span>
        </Col>
    </Row>
    <Row  className={styles.row}>
        <Col className={styles.left} md='12'>
            <span className={styles.text}>הרה"ג ר' יעקב מנחם שטייף שליט"א</span>
        </Col>
    </Row>
    <Row  className={styles.row}>
        <Col className={styles.left} md='12'>
            <span className={styles.text}>הרה"ג ר' שמואל רובין שליט"א</span>
        </Col>
    </Row>
</Container>
    )
}

export default Rabbis;