import React from 'react'
import styles from './Contact.module.css'
import { Col, Container, Row} from 'react-bootstrap';
import { ContactUsForm } from './ContactUsForm';
import Confirm from '../shared/Confirm'
import { useTitle } from 'hooks/useTitle';

const Contact = () => {
    useTitle("Hatzolah of Linden | Contact Us");

    return (
        <Container className={`${styles.contact}`}>
            <Row>
                <Col sm="12" md='6'>
                    <div className={`flex-col ${styles.contactStory}`}>
                        <span className={`flex-col ${styles.caption}`}>WE'D LOVE TO HEAR FROM YOU!</span>
                        <br/>
                        <p>Get in touch with us! Learn more about our mission, our team, and what are our plans for the future.
                        We welcome any questions you may have, and we'd love to get acquainted with you as well, so feel free to shoot us an email using the form on the right.
                        Alternatively, you can email us at: <button  className ={`${styles.email}`} onClick={() => window.open("mailto:office@lindenhatzolah.com/")}>office@lindenhatzolah.com</button>.
                        </p>
                    </div>
                </Col>
                <Col sm="12" md='6'>
                    <ContactUsForm/>
                    <Confirm/>
                </Col>
            </Row>
        </Container>
    )
}

export default Contact;