import React from 'react'
import { Col, Container, Row,Image} from 'react-bootstrap';
import './Story.css'

const Story = () =>{
    return ( 
        <Container className='story'>
            <Row>
                <Col className="history" sm='12' md='6'>
                    <h3>OUR HISTORY</h3>
                    <p ><b>Hatzolah of Linden</b>&nbsp;
                    was founded in 2021 to serve the newly established communty of Linden, NJ.
                    As our community continues to grow beyond the borders of Linden, we're expanding our reach to cover 
                    other neighborhoods in our immediate vicinity, in order to ensure a timely response to any medical emergency, wherever it may occur. We currently serve the communities of <b>Linden</b>,&nbsp;  
                    <b>Roselle</b>, <b>Clark</b>, <b>Winfield</b>, <b>Rahway</b> &amp; <b>Cranford</b>.</p><br/><br/>
                </Col>
                <Col className={`member-on-call`}  sm='12' md='6'>
                    <Image  alt="Photo of member on call" src='member-on-call.jpg'/>  
                </Col>
            </Row>
            <br/><br/>
            <Row className='meet-us-row'>
                <Col className={`meet-us`} sm='12'  md='6'>
                    <Image alt="Meet Us Photo" src='about_us.jpg'/> 
                </Col>
                <Col className="mission"  sm='12' md='6'>
                    <h3>OUR MISSION</h3>
                    <p className=""><b>Hatzolah of Linden</b>&nbsp; operates 24 hours a day, seven days a week, with a staff of extremely devoted &amp; highly trained volunteers, available to respond at a moments notice to any situation.
                        Our goal is to deliver the highest level of emergency care, and ensure rapid, safe transport to the appropriate medical facility.
                    </p>
                </Col>
            </Row>
        </Container>
);
}

export default Story;