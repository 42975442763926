import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './ContactInfo.module.css'

const Coordinators = () => {
    return (
    <Container className={styles.contactInfo}>
    <Row className={styles.row}>
        <Col className={styles.left} md='12'>
            <span className={styles.caption}><b>COORDINATORS</b></span>
        </Col>
    </Row>
    <Row style={{height:'15px'}}>
    </Row>
    <Row  className={styles.row}>
        <Col className={styles.left} md='12'>
            <span className={styles.text}>Yossi Hoffman, NREMTP</span>
        </Col>
    </Row>
    <Row  className={styles.row}>
        <Col className={styles.left} md='12'>
            <span className={styles.text}>Sruly Knobloch, NREMTP</span>
        </Col>
    </Row>
</Container>
    )
}

export default Coordinators;