import React, { useRef, useState } from 'react';
import { Container,Form, Button } from 'react-bootstrap';
import styles from './ContactUsForm.module.css';
import Confirm from '../shared/Confirm'

export const ContactUsForm = () => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const form = useRef();

  // const submit = (e) => {
  //   e.preventDefault(e);
  //   setShowConfirmation(true)
  // }

  const yesFn = () =>{
    setShowConfirmation(false);
    sendEmail();
  };

  const noFn = () => {
    setShowConfirmation(false);
  };

  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  // const sendEmail = (e) => {  
  //   emailjs.sendForm('gmail', 'template_en3pahb', form.current, 'user_c8YubHusB5yJmnv77HZ3a')
  //     .then((result) => {
  //         console.log(result.text);
  //     }, (error) => {
  //       console.log(error)
  //         console.log(error.text);
  //     });
  // };

  const sendEmail = (e) => {
    e.preventDefault();

    const userName = e.target.user_name; // accessing directly
    const email = e.target.user_email;
    const message = e.target.message;

    const values = {
      'from_name':userName.value,
      'from_email':email.value,
      'message':message.value
    }

    const data = {
        service_id: 'gmail',
        template_id: 'template_en3pahb',
        user_id: 'user_c8YubHusB5yJmnv77HZ3a',
        template_params: values
    };

    const bodyString = JSON.stringify(data,getCircularReplacer());

    fetch('https://api.emailjs.com/api/v1.0/email/send', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: bodyString
    }).then((result) => {
        console.log(result.statusText);
    }, (error) => {
        console.log(error.statusText);
    });
    
    e.target.reset();
  };

  return (
    <Container className={`flex-col ${styles.contactForm}`}>
      <Confirm yesFn={yesFn} noFn={noFn} caption='Confirm Send Email' body='Your email message is about to be sent. Are you sure?' show={showConfirmation}/>
      <Form ref={form} onSubmit={sendEmail}>
        <Form.Group controlId="form.Name">
            <Form.Label>Name</Form.Label>
            <Form.Control type="text" name="user_name" placeholder="Enter your name" />
        </Form.Group><br/>
        <Form.Group controlId="form.Email">
            <Form.Label>Email address</Form.Label>
            <Form.Control  type="email" name="user_email"  placeholder="name@example.com" />
        </Form.Group><br/>
        <Form.Group controlId="form.Textarea">
            <Form.Label>Message</Form.Label>
            <Form.Control name='message' as="textarea" placeholder="Please type in your message" rows={3} />
        </Form.Group><br/>
        <Form.Group className={` ${styles.submitButtonWrapper}`} controlId="form.SubmitButton">
            <Button className={`flex-col ${styles.submitButton}`} variant="primary" value="submit" type="submit">SEND US YOUR MESSAGE!</Button>
        </Form.Group>
      </Form> 
    </Container>
   )};
