import React from 'react'
import { Container, Row} from 'react-bootstrap';
import styles from './Foreground.module.css'
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollar} from "@fortawesome/free-solid-svg-icons"

const Foreground = () => {
    return (
        <Container fluid className={` ${styles.foreground}`}>
            <Row className={styles.top}>
                <span className={styles.savingLives}>WE'RE SAVING LIVES....</span><br/>
                <span className={styles.everyDay}>EVERY SINGLE DAY.</span>
            </Row>
            <Row className={styles.donation}>
                        <span>BE A PART OF IT.</span><br/>
                        <a href='/donate'><button className={`btn-primary ${styles.btn}`}>DONATE NOW</button></a>
            </Row> 
            <Row className={styles.emergency}>
                    <span>IN CASE OF EMERGENCY, PLEASE CALL: <b>908-800-9200</b></span>
            </Row> 
            <div className={`${styles.partner}`} >
            <div  className={`${styles.iconWrap}`}><div className={`${styles.icon}`}><FontAwesomeIcon icon={faDollar} /></div></div>
            <br/>
            <span>PARTNER WITH US</span><br/>
            <p style={{}}>
                Sponsor an ambulance, equipment or medical supplies in memory of a loved one.
                Please contact us for a list of dedication opportunities.
            </p>
            <a href='/contact'><button className={`btn-primary ${styles.btn}`}>LEARN MORE</button></a>
        </div>
        </Container>
    );
};

export default  Foreground;