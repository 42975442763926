import React from 'react'
import {Image } from 'react-bootstrap';
import './Banner.css'

export const Banner = () => {
    return (
        <div style={{}} className="flex-row banner">
            <a className="" href="/">
                <Image className='logo' alt="LindenHatzolahLogo" src="linden-hatzolah-logo.png" rounded />
            </a>
            <h1 className='brand-caption'>HATZOLAH <span className='of'>OF</span> LINDEN</h1>
        </div>
    )
}