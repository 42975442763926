import React from 'react'
import { Col, Container, Row ,Image} from 'react-bootstrap';
import './Background.css'

const Background = (props) => {
    return(<> 
    <Container className='background' fluid>
        {props.children}
        <Row className={`row`}>
            <Col md='12' style={{height:'80%',width:'',padding:'0'}}>
                <Image alt="Photo of ambulances speeding" style={{backgroundRepeat: 'repeat-x',opacity:'45%', height:'80%',width:'100%', backgroundSize:'150px',resizeMode: 'cover'}} src='ambulances-speeding.jpg'/> 
            </Col>
        </Row>
        <Row>
            <Col md='12' style={{height:'80%',width:'',padding:'0'}}>
                <Image alt="Photo of ambulances 2" style={{backgroundRepeat: 'repeat-x',opacity:'45%', height:'80%',width:'100%', backgroundSize:'150px', resizeMode: 'cover'}} src='ambulance2.jpg'/> 
            </Col>
        </Row>
    </Container>
    </>
    )
}

export default Background;