import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import React from 'react';
import Footer from 'footer/Footer';
import { Content } from 'Content';
import { Header } from 'header/Header';

function App() {
  return (
    <Router>
    <div  className="wrapper">
      <Header/>
      <Content/>
      <Footer/>
    </div>
    </Router>
  )}

export default App;
