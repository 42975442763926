import React from 'react'
import './About.css'
import Story from 'about/Story';
import { useTitle } from 'hooks/useTitle';

const About = () => {
    useTitle("Hatzolah of Linden | About Us");
    
    return (
        <div className='about'>
            <div>
                <h1>ABOUT US</h1>
            </div>
            <br/>
            <div>
                <Story/>
            </div>
        </div>
    )
}

export default About;