import About from 'about/About'
import Contact from 'contact/Contact'
import Donate from 'donate/Donate'
import Home from 'home/Home'
import React from 'react'
import { Route } from 'react-router-dom'

export const Content = () => {
    return(
    <div className="content">
    <Route exact path="/" component={Home} />
    <Route path="/donate" component={Donate} />
    <Route path="/contact" component={Contact} />
    <Route path="/about" component={About} />
    </div>
    )
}