import { useTitle } from 'hooks/useTitle';
import React from 'react'
import './Donate.css'

const Donate = () => {
    useTitle("Hatzolah of Linden | Donate");

    return (
        <div className='donate'>
            <iframe className='iframe' title='Donate'  src="https://secure.cardknox.com/hatzolahoflinden"></iframe>
        </div>
    )
}

export default Donate;