import React from 'react'
import { Banner } from './banner/Banner'
import { Subcaption } from './subcaption/Subcaption'
import TopNav from './top-nav/TopNav'

export const Header = () => {
    return (
        <div style={{}} className="">
            <header>
                <TopNav/>
                <Banner/>
                <Subcaption/>
            </header>
        </div>
    )
}